@import "scss/variables";
@import "scss/mixins";

html, body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

body {
  font-family: $base-font;
  font-weight: 400;
  user-select: none;
  @include outline;
}

@import "scss/analog-clock";
@import "scss/beat-saber";
@import "scss/chat";
@import "scss/connection";
@import "scss/credits";
@import "scss/dialog";
@import "scss/event-alert";
@import "scss/heart-rate";
