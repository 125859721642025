html, body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

body {
  -webkit-user-select: none;
  user-select: none;
  filter: drop-shadow(-1px -1px .75px #212f4a) drop-shadow(-1px 1px .75px #212f4a) drop-shadow(1px -1px .75px #212f4a) drop-shadow(1px 1px .75px #212f4a);
  font-family: Kalam, cursive;
  font-weight: 400;
}

.analog-clock {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
}

.analog-clock .second-hand, .analog-clock .minute-hand, .analog-clock .hour-hand {
  fill: currentColor;
  transform-origin: 32px 32px;
  transition: transform 1s linear;
}

.analog-clock .clock-base {
  opacity: .6;
}

.analog-clock .clock-face, .analog-clock .clock-ticking {
  fill: none;
  stroke: currentColor;
}

.analog-clock .clock-face {
  stroke-width: 4px;
}

.analog-clock .clock-ticking {
  stroke-width: 3px;
}

.analog-clock .second-hand {
  fill: none;
  stroke: red;
  stroke-width: 2px;
}

.song-name, .song-author, .song-details, #connection {
  line-height: 1em;
}

#beat-saber {
  cursor: pointer;
  color: #fff;
  font-size: calc(var(--beat-saber-base-font-size) * 3.5);
  text-align: right;
  z-index: 4;
  max-width: calc(100% - 3rem);
  font-family: Teko, sans-serif;
  font-style: italic;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.data-layout {
  width: 100%;
  margin-bottom: calc(var(--beat-saber-base-font-size) * 1);
  opacity: 0;
  will-change: transform;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  transition-property: transform, opacity;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(.68, -.6, .32, 1.6);
  display: flex;
  transform: translate(100%);
}

.data-layout.connected > * {
  font-style: normal;
  transform: skew(-10deg);
}

.data-layout .song-name, .data-layout .song-author, .data-layout .song-details, .data-layout #connection {
  opacity: 0;
  will-change: transform;
  transition-property: transform, opacity;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(.68, -.6, .32, 1.6);
  transform: translate(100%);
}

.data-layout.show, .data-layout.show .song-name, .data-layout.show .song-author, .data-layout.show .song-details, .data-layout.show #connection {
  opacity: 1;
  transform: translate(0%);
}

.data-layout .song-info-block {
  padding-right: .342em;
}

.song-info-block {
  min-width: 0;
  flex-flow: column;
  flex: 0 0 calc(100% - 2.85em);
  justify-content: space-around;
  align-items: stretch;
  display: flex;
}

.song-info-block .song-info-line {
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.song-info-block .song-info-line, .song-info-block .song-info-line * {
  overflow: hidden;
}

.song-info-block .song-info-line > * {
  transform: skew(-5deg);
}

.song-info-block .song-info-line, .song-info-block .song-info-line > * {
  margin-top: -.2em;
  margin-bottom: -.2em;
  padding-top: .2em;
  padding-bottom: .2em;
}

.song-info-block .song-info-line .song-info-line-original.hide {
  visibility: hidden;
}

.song-info-block .song-info-line .song-info-line-marquee {
  position: absolute;
  inset: 0;
}

.song-info-block .song-info-line .song-info-line-marquee.hide {
  visibility: hidden;
}

.song-info-block .song-info-line .song-info-line-marquee .song-info-line-marquee-item {
  margin-right: 1em;
}

.song-cover-image {
  width: 2.85em;
  height: 2.85em;
  background: #000;
  border-radius: .285em;
  flex: 0 0 2.85em;
  display: block;
}

.song-name {
  transition-delay: 50ms;
}

.song-name .sub-name {
  margin-left: .35em;
  font-size: .8em;
}

.song-author {
  color: #ffffffbf;
  font-size: .7em;
  transition-delay: .1s;
}

.song-author .mapper {
  color: #67c271;
}

.song-details {
  font-size: .5em;
  transition-delay: .15s;
}

.song-details:empty {
  display: none;
}

.song-details .song-detail-items > :not(:first-child):before {
  content: "•";
  opacity: .5;
  color: #fff;
  margin: 0 .25em;
}

#beat-saber-additional-data-root {
  opacity: 0;
  will-change: transform;
  flex-flow: row;
  justify-content: flex-end;
  transition: transform 2s cubic-bezier(.68, -.6, .32, 1.6) .2s, opacity 2s cubic-bezier(.68, -.6, .32, 1.6) .2s;
  display: flex;
  transform: translate(100%);
}

#beat-saber-additional-data-root.show {
  opacity: 1;
  transform: translate(0%);
}

#beat-saber-additional-data-root > div {
  flex-flow: column;
  align-items: flex-end;
  display: flex;
}

#beat-saber-additional-data-root > div:not(:last-child) {
  margin-right: calc(var(--beat-saber-base-font-size) * 2);
}

#beat-saber-additional-data-root .accuracy-graph-label > * {
  white-space: nowrap;
}

#beat-saber-additional-data-root .accuracy-graph-label > :not(:last-child) {
  margin-right: calc(var(--beat-saber-base-font-size) * .75);
}

#beat-saber-additional-data-root .accuracy-graph-label .fixed-width {
  text-align: center;
  display: inline-block;
}

#beat-saber-additional-data-root .accuracy-graph-label .accuracy-percent {
  min-width: calc(var(--beat-saber-base-font-size) * 6.5);
}

#beat-saber-additional-data-root .accuracy-graph-label .accuracy-rating {
  min-width: calc(var(--beat-saber-base-font-size) * 4.1);
}

#beat-saber-additional-data-root .accuracy-graph-label .energy-amount {
  min-width: calc(var(--beat-saber-base-font-size) * 2.5);
}

#beat-saber-additional-data-root .accuracy-graph-label .energy svg {
  margin-left: calc(var(--beat-saber-base-font-size) * .5);
  height: .7em;
  display: inline-block;
}

#beat-saber-additional-data-root .accuracy-graph-label {
  font-size: calc(var(--beat-saber-base-font-size) * 2.5);
}

.accuracy-graph-wrapper {
  border-radius: calc(var(--beat-saber-base-font-size) * .25);
  background-image: linear-gradient(#ffffff4d 0%, #ffffff1a 100%);
  display: block;
  position: relative;
  transform: skew(-8deg);
}

.accuracy-graph-wrapper .graph-legend-wrapper {
  position: absolute;
}

.accuracy-graph-wrapper .graph-legend-wrapper .graph-legend {
  font-size: calc(var(--beat-saber-base-font-size) * 1.5);
  color: #ccc;
  line-height: 1em;
}

.accuracy-graph-wrapper .accuracy-graph-range {
  top: 0;
  left: calc(-1 * var(--beat-saber-base-font-size) * 6);
  height: 100%;
  padding-right: calc(var(--beat-saber-base-font-size) * .5);
  text-align: right;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  bottom: 0;
  right: 100%;
}

.accuracy-graph-wrapper .accuracy-graph-range .graph-legend.top {
  position: relative;
  transform: translateY(-40%);
}

.accuracy-graph-wrapper .accuracy-graph-range .graph-legend.bottom {
  position: relative;
  transform: translateY(40%);
}

.accuracy-graph-wrapper .accuracy-graph-duration {
  top: 100%;
  left: 0;
  bottom: calc(-1 * var(--beat-saber-base-font-size) * 2);
  width: 100%;
  padding-top: calc(var(--beat-saber-base-font-size) * .5);
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  right: 0;
}

.accuracy-graph-wrapper .accuracy-graph-duration .graph-legend {
  width: 0;
  display: block;
}

.accuracy-graph-wrapper .accuracy-graph-duration .graph-legend span {
  display: inline-block;
  transform: translateX(-50%);
}

.accuracy-graph-wrapper canvas {
  border-radius: inherit;
  display: block;
}

#beat-saber-modifiers-root .modifiers-label {
  font-size: calc(var(--beat-saber-base-font-size) * 2.5);
}

#beat-saber-modifiers-root .modifiers-list {
  font-size: calc(var(--beat-saber-base-font-size) * 2);
  margin: 0;
  padding: 0;
  line-height: 1em;
  list-style: none;
}

#beat-saber-note-pile-root {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

#beat-saber-note-pile-root canvas {
  width: 100%;
  height: 100%;
}

#chat-root {
  width: 500px;
  color: #fff;
  cursor: pointer;
  flex-flow: column;
  justify-content: flex-end;
  align-items: stretch;
  padding: 0 1rem;
  font-size: 2rem;
  font-style: normal;
  line-height: 1.2;
  display: flex;
  position: absolute;
  top: 9rem;
  bottom: 13rem;
  left: 0;
  overflow: hidden;
}

#chat-root .chat-message {
  width: 100%;
  transform-origin: 0;
  will-change: transform;
  flex: none;
  animation-name: show-chat-message, hide-chat-message;
  animation-duration: .4s, 1s;
  animation-timing-function: cubic-bezier(.34, 1.56, .64, 1), ease-in;
  animation-delay: 0s, 30s;
  animation-fill-mode: forwards;
  display: block;
  position: relative;
  overflow: hidden;
}

@keyframes show-chat-message {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hide-chat-message {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

#chat-root .chat-message:not(:last-child) {
  margin-bottom: .25em;
}

#chat-root .chat-message.chat-message-system {
  font-size: .85em;
}

#chat-root .chat-message .chat-message-author {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: .1em;
  display: flex;
}

#chat-root .chat-message .chat-message-author > :not(:last-child) {
  margin-right: .5em;
}

#chat-root .chat-message .chat-message-author .chat-message-author-pronouns {
  padding: 0 .3em;
  font-size: .6em;
}

#chat-root .chat-message .chat-message-author .chat-message-author-name {
  color: var(--accent-color);
  font-weight: 700;
}

#chat-root .chat-message-body {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  width: 100%;
  box-sizing: border-box;
  padding: .3em .25em;
  font-size: .9em;
  display: block;
  position: relative;
}

#chat-root .chat-message-body:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  background-image: linear-gradient(to bottom, var(--accent-color-80) 0, var(--accent-color-20) 100%);
  opacity: .2;
  will-change: transform;
  border: .15em solid var(--accent-color);
  box-sizing: border-box;
  border-right-width: 0;
  border-radius: .2em;
  animation-name: shrink-message-background;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-delay: .4s;
  animation-fill-mode: forwards;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleY(1);
}

@keyframes shrink-message-background {
  to {
    width: 0;
  }
}

#chat-root .chat-message-body .chat-message-send-timestamp {
  opacity: .5;
  margin-right: .5em;
  font-size: .75em;
  font-weight: bold;
}

#chat-root .chat-message-body .chat-message-emote {
  --size: 1em;
  width: var(--size);
  height: var(--size);
  object-fit: contain;
  display: inline-block;
}

#chat-root .chat-message-body .chat-message-emote.large {
  --size: 1.5em;
  vertical-align: bottom;
}

.loading-indicator.connection-loading {
  border: calc(calc(var(--beat-saber-base-font-size) * 6.5) * .08) solid #ffffff59;
  transform-origin: 50%;
  width: calc(calc(var(--beat-saber-base-font-size) * 6.5) * .8);
  height: calc(calc(var(--beat-saber-base-font-size) * 6.5) * .8);
  margin: calc((calc(var(--beat-saber-base-font-size) * 6.5)  - calc(var(--beat-saber-base-font-size) * 6.5) * .8) * .5);
  box-sizing: border-box;
  border-top-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: .57em;
  animation: 2s cubic-bezier(.37, 0, .63, 1) infinite rotate;
  display: flex;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1080deg);
  }
}

#beat-saber-root:empty {
  border: calc(calc(var(--beat-saber-base-font-size) * 6.5) * .08) solid #80808059;
  transform-origin: 50%;
  width: calc(calc(var(--beat-saber-base-font-size) * 6.5) * .8);
  height: calc(calc(var(--beat-saber-base-font-size) * 6.5) * .8);
  margin: calc((calc(var(--beat-saber-base-font-size) * 6.5)  - calc(var(--beat-saber-base-font-size) * 6.5) * .8) * .5);
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

#connection-root {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

#connection-root .connection-wrap {
  margin-bottom: calc(var(--beat-saber-base-font-size) * 1);
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

#connection-root .connection-status {
  font-size: calc(var(--beat-saber-base-font-size) * 3.5);
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  transition-delay: 50ms;
}

#connection-root .connection-status > * {
  display: block;
}

#connection-root .connection-status .status {
  font-size: calc(var(--beat-saber-base-font-size) * 1.75);
}

#connection-root .time-elapsed {
  font-size: calc(var(--beat-saber-base-font-size) * 2);
  line-height: 1em;
}

.credits-clock {
  color: #fff;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.credits-clock > * {
  flex-flow: row;
  align-items: center;
  line-height: .75em;
  display: flex;
}

.credits-clock .time {
  margin-bottom: .15em;
  font-size: 4rem;
}

.credits-clock .time-meta {
  font-size: 1.25rem;
}

.credits-clock .time-meta > :not(:first-child):before {
  content: "•";
  opacity: .5;
  color: #fff;
  margin: 0 .25em;
}

.credits-clock .time-formats {
  height: 1em;
  width: 6em;
  margin-left: .25em;
  display: block;
  position: relative;
  overflow: hidden;
}

.credits-clock .time-formats .time-value {
  height: 1em;
  will-change: transform, opacity;
  line-height: 1.2em;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.credits-clock .time-formats:not(.skip-animation) .time-value {
  animation-duration: .6s;
  animation-timing-function: cubic-bezier(.68, -.6, .32, 1.6);
  animation-fill-mode: forwards;
}

.credits-clock .time-formats:not(.skip-animation)[data-format="12"] .time-12 {
  animation-name: rotate-in;
}

.credits-clock .time-formats:not(.skip-animation)[data-format="12"] .time-24, .credits-clock .time-formats:not(.skip-animation)[data-format="24"] .time-12 {
  animation-name: rotate-out;
}

.credits-clock .time-formats:not(.skip-animation)[data-format="24"] .time-24 {
  animation-name: rotate-in;
}

.credits-clock .time-formats.skip-animation[data-format="12"] .time-24, .credits-clock .time-formats.skip-animation[data-format="24"] .time-12 {
  transform: translateY(-1em);
}

@keyframes rotate-in {
  from {
    transform: translateY(-1em);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes rotate-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(1em);
  }
}

dialog {
  color: #ddd;
  cursor: initial;
  background-color: #101010;
  border: .15rem solid #555;
  border-radius: 1rem;
  padding: 2rem;
  font-size: 1.75rem;
  font-style: normal;
  box-shadow: 0 0 1rem #555;
}

dialog h1, dialog h2, dialog h3 {
  margin: 0 0 1rem;
  font-weight: normal;
}

dialog details {
  border-left: .05em solid;
  margin-bottom: 1em;
  padding-left: 1em;
}

dialog details summary {
  cursor: pointer;
}

dialog details summary h1, dialog details summary h2, dialog details summary h3 {
  margin: 0;
  display: inline-block;
}

dialog p {
  margin: 0 0 .25rem;
}

dialog a {
  color: #dcdcff;
}

dialog a:visited {
  color: #ffdcff;
}

dialog input[type="text"], dialog input[type="number"], dialog input[type="password"], dialog input[type="url"], dialog textarea {
  width: 100%;
  color: #ddd;
  box-sizing: border-box;
  line-height: 1;
  font-size: inherit;
  background-color: #0000;
  border: 2px solid gray;
  border-radius: .25em;
  margin-bottom: 1rem;
  padding: .25em .75em;
  display: block;
}

dialog input[type="text"]:disabled, dialog input[type="number"]:disabled, dialog input[type="password"]:disabled, dialog input[type="url"]:disabled, dialog textarea:disabled {
  opacity: .5;
}

dialog input[type="text"]:active, dialog input[type="number"]:active, dialog input[type="password"]:active, dialog input[type="url"]:active, dialog textarea:active {
  border-color: #ddd;
}

dialog input[type="checkbox"], dialog input[type="radio"] {
  width: 1em;
  height: 1em;
  font-size: 1em;
  line-height: 1em;
}

dialog input:focus, dialog textarea:focus {
  border-bottom-color: #ddd;
}

dialog input.small, dialog textarea.small {
  padding: .75rem;
  font-size: 1rem;
}

dialog button {
  color: #ddd;
  box-sizing: border-box;
  font-style: normal;
  line-height: 1;
  font-size: inherit;
  background-color: #0000;
  border: 2px solid gray;
  border-radius: .25em;
  padding: .25em .75em;
}

dialog button:disabled {
  opacity: .5;
}

dialog button:active {
  border-color: #ddd;
}

dialog button:not(:last-child) {
  margin-right: 1rem;
}

dialog button.inline {
  margin: 0 .5em;
}

dialog code {
  background-color: #313131;
  border-radius: .15em;
  padding: .2em;
  font-size: .75em;
}

#event-alert {
  justify-content: flex-start;
  align-items: center;
  font-size: 5.25rem;
  line-height: 1em;
  display: flex;
}

#event-alert #event-alert-icon {
  width: .9em;
  height: .9em;
}

#event-alert #event-alert-message {
  transform-origin: 0;
  margin: 0 0 0 .25em;
  padding: 0;
  animation: 1s ease-out forwards alert-appear, 3s linear infinite alert-color-shift;
}

@keyframes alert-appear {
  from {
    opacity: 0;
    transform: rotateY(-90deg)translateY(.12em);
  }

  to {
    opacity: 1;
    transform: rotateY(0)translateY(.12em);
  }
}

@keyframes alert-color-shift {
  0%, 100% {
    color: #bdd3f8;
  }

  50% {
    color: #7ba6f2;
  }
}

#heart-rate {
  z-index: 3;
  opacity: 0;
  flex-flow: column;
  align-items: flex-start;
  transition: opacity .2s linear;
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

#heart-rate > :not(:last-child) {
  margin-bottom: .5rem;
}

#heart-rate.show, #heart-rate:hover {
  opacity: 1;
}

#heart-rate:hover .device-name.connected {
  opacity: 1 !important;
}

#heart-rate .label {
  color: #fff;
  font-family: inherit;
  font-style: inherit;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  transition: opacity .2s linear;
  bottom: 10rem;
}

#heart-rate .label.device-name {
  cursor: pointer;
}

#heart-rate .label.device-name.connected {
  opacity: 0;
}

#heart-rate .label.device-name:hover:after {
  content: "×";
  color: red;
  padding-left: .5rem;
  font-family: sans-serif;
  font-style: normal;
}

#heart-rate .label .device-icon {
  width: .7em;
  height: .7em;
  vertical-align: baseline;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: .1em;
  display: inline-block;
}

.bluetooth-bg {
  background-image: url("bluetooth.131884ee.svg");
}

.pulsoid-bg {
  background-image: url("pulsoid.8937134a.svg");
}

.websocket-bg {
  background-image: url("websocket.1a1c58c5.svg");
}

#heart-rate .connections {
  flex-flow: row;
  display: flex;
}

#heart-rate .connections .connection-button {
  appearance: none;
  color: #0000;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  opacity: .5;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-width: 0;
  margin-right: 1rem;
  font-size: 0;
  transition: opacity .2s linear;
  overflow: hidden;
}

#heart-rate .connections .connection-button:hover {
  opacity: 1;
}

#heart-rate .display {
  height: 3rem;
  flex-flow: row;
  display: flex;
}

#heart-rate .display .heart, #heart-rate .display .rate-number {
  font-size: 0;
  display: block;
}

#heart-rate .display .heart {
  height: 3rem;
  width: 3rem;
  background: url("heart.b99f9d8b.svg") center / contain no-repeat;
  margin-right: .5em;
  transform: scale(1.15);
}

#heart-rate .display .rate-number {
  height: 1em;
  width: 1em;
  background-image: url("humbers.ac3d88af.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 10em 1em;
  margin-right: -.25em;
  font-size: 3rem;
}

#heart-rate .display .rate-number[data-value="1"] {
  margin-right: -.5em;
}

.loading-indicator.heart-rate-loading {
  transform-origin: 50%;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  border: .24rem solid #bb98d259;
  border-top-color: #bb98d2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: .3rem;
  animation: 2s cubic-bezier(.37, 0, .63, 1) infinite rotate;
  display: flex;
}

.loading-indicator.ble-loading {
  transform-origin: 50%;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  border: .24rem solid #1266ef59;
  border-top-color: #1266ef;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: .3rem;
  animation: 2s cubic-bezier(.37, 0, .63, 1) infinite rotate;
  display: flex;
}

.loading-indicator.pulsoid-loading {
  transform-origin: 50%;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  border: .24rem solid #10a3b059;
  border-top-color: #10a3b0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: .3rem;
  animation: 2s cubic-bezier(.37, 0, .63, 1) infinite rotate;
  display: flex;
}

.loading-indicator.websocket-loading {
  transform-origin: 50%;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  border: .24rem solid #ffffff59;
  border-top-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: .3rem;
  animation: 2s cubic-bezier(.37, 0, .63, 1) infinite rotate;
  display: flex;
}

.loading-indicator.beat-saver-map {
  transform-origin: 50%;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  border: .24rem solid #375a7f59;
  border-top-color: #375a7f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: .3rem;
  animation: 2s cubic-bezier(.37, 0, .63, 1) infinite rotate;
  display: flex;
}

/*# sourceMappingURL=index.e4be7e67.css.map */
