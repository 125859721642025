@use "sass:math";

$connect-icon-size: 3rem;

#heart-rate {
  position: absolute;
  bottom: $edge-spacing;
  left: $edge-spacing;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  z-index: map-get($z-index-map, 'heart-rate');
  opacity: 0;
  transition: opacity .2s linear;

  > :not(:last-child) {
    margin-bottom: .5rem;
  }

  &.show, &:hover {
    opacity: 1;
  }

  &:hover {
    .device-name.connected {
      opacity: 1 !important;
    }
  }

  .label {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: #fff;
    bottom: 10rem;
    font-family: inherit;
    font-style: inherit;
    font-size: 1.5rem;
    transition: opacity .2s linear;

    &.device-name {
      cursor: pointer;

      &.connected {
        opacity: 0;
      }

      &:hover::after {
        content: "×";
        padding-left: .5rem;
        color: red;
        font-family: sans-serif;
        font-style: normal;
      }
    }

    .device-icon {
      display: inline-block;
      width: .7em;
      height: .7em;
      margin-right: .1em;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      vertical-align: baseline;
    }

  }
}

.bluetooth-bg {
  background-image: url("./img/bluetooth.svg");
}

.pulsoid-bg {
  background-image: url("./img/pulsoid.svg");
}

.websocket-bg {
  background-image: url("./img/websocket.svg");
}

#heart-rate {
  .connections {
    display: flex;
    flex-flow: row nowrap;

    .connection-button {
      appearance: none;
      color: transparent;
      font-size: 0;
      overflow: hidden;
      width: $connect-icon-size;
      height: $connect-icon-size;
      border-width: 0;
      cursor: pointer;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: opacity .2s linear;
      opacity: .5;
      margin-right: 1rem;

      &:hover {
        opacity: 1;
      }
    }
  }

  .display {
    display: flex;
    flex-flow: row nowrap;
    height: $connect-icon-size;

    .heart, .rate-number {
      display: block;
      font-size: 0;
    }

    .heart {
      height: $connect-icon-size;
      width: $connect-icon-size;
      transform: scale(1.15);
      background: url(img/heart.svg) no-repeat center;
      background-size: contain;
      margin-right: 0.5em;
    }

    .rate-number {
      $spacing: -0.25em;
      font-size: $connect-icon-size;
      height: 1em;
      width: 1em;
      background-image: url(img/humbers.svg);
      background-repeat: no-repeat;
      background-size: 10em 1em;
      background-position: 0 0;
      margin-right: $spacing;

      &[data-value="1"] {
        margin-right: $spacing * 2;
      }
    }
  }
}

.loading-indicator {
  &.heart-rate-loading {
    @include loading-element($connect-icon-size, #bb98d2);
  }

  &.ble-loading {
    @include loading-element($connect-icon-size, lighten(#0a3d91, 20%));
  }

  &.pulsoid-loading {
    @include loading-element($connect-icon-size, #10a3b0);
  }

  &.websocket-loading {
    @include loading-element($connect-icon-size, #fff);
  }

  &.beat-saver-map {
    @include loading-element($connect-icon-size, #375a7f);
  }
}
