#chat-root {
  $font-size: 2rem;
  $show-message-delay: 0s;
  $show-message-duration: .4s;
  $hide-message-delay: 30s;
  $hide-message-duration: 1s;
  position: absolute;
  left: 0;
  top: 9rem;
  bottom: 13rem;
  width: 500px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: stretch;
  color: #fff;
  padding: 0 1rem;
  font-size: $font-size;
  font-style: normal;
  line-height: 1.2;
  overflow: hidden;
  cursor: pointer;

  .chat-message {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    animation-name: show-chat-message, hide-chat-message;
    animation-delay: $show-message-delay, $hide-message-delay;
    animation-timing-function: $ease-out-back, ease-in;
    animation-fill-mode: forwards;
    animation-duration: $show-message-duration, $hide-message-duration;
    transform-origin: 0 50%;
    will-change: transform;

    @keyframes show-chat-message {
      from {
        opacity: 0;
        transform: translateX(-50%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @keyframes hide-chat-message {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: .25em;
    }

    &.chat-message-system {
      font-size: .85em;
    }

    .chat-message-author {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: .1em;

      > *:not(:last-child) {
        margin-right: .5em;
      }

      .chat-message-author-pronouns {
        font-size: .6em;
        padding: 0 0.3em;
      }

      .chat-message-author-name {
        font-weight: 700;
        color: var(--accent-color);
      }
    }
  }

  .chat-message-body {
    overflow-wrap: break-word;
    hyphens: auto;
    font-size: .9em;
    position: relative;
    display: block;
    width: 100%;
    padding: .3em .25em;
    box-sizing: border-box;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      background-image: linear-gradient(to bottom, var(--accent-color-80) 0, var(--accent-color-20) 100%);
      border-radius: .2em;
      opacity: .2;
      transform: scaleY(1);
      will-change: transform;
      animation-name: shrink-message-background;
      animation-duration: $hide-message-delay;
      animation-delay: $show-message-duration + $show-message-delay;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      border: .15em solid var(--accent-color);
      border-right-width: 0;
      box-sizing: border-box;
    }

    @keyframes shrink-message-background {
      to {
        width: 0;
      }
    }

    .chat-message-send-timestamp {
      opacity: .5;
      font-size: .75em;
      margin-right: .5em;
      font-weight: bold;
    }

    .chat-message-emote {
      --size: 1em;
      width: var(--size);
      height: var(--size);
      display: inline-block;
      object-fit: contain;

      &.large {
        --size: 1.5em;
        vertical-align: bottom;
      }
    }
  }
}
