.credits-clock {
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  > * {
    line-height: .75em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .time {
    font-size: 4rem;
    margin-bottom: .15em;
  }

  .time-meta {
    font-size: 1.25rem;

    @include dots-between-children;
  }

  .time-formats {
    $height: 1em;
    display: block;
    height: $height;
    overflow: hidden;
    margin-left: .25em;
    width: 6em;
    position: relative;

    .time-value {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: $height;
      line-height: $height * 1.2;
      will-change: transform, opacity;
    }

    &:not(.skip-animation) {
      .time-value {
        animation-duration: .6s;
        animation-timing-function: $ease-in-out-back;
        animation-fill-mode: forwards;
      }

      &[data-format="12"] {
        .time-12 {
          animation-name: rotate-in;
        }

        .time-24 {
          animation-name: rotate-out;
        }
      }

      &[data-format="24"] {
        .time-12 {
          animation-name: rotate-out;
        }

        .time-24 {
          animation-name: rotate-in;
        }
      }
    }

    &.skip-animation {
      &[data-format="12"] .time-24,
      &[data-format="24"] .time-12 {
        transform: translateY(-$height);
      }
    }

    @keyframes rotate-in {
      from {
        transform: translateY(-$height);
      }

      to {
        transform: translateY(0);
      }
    }

    @keyframes rotate-out {
      from {
        transform: translateY(0);
      }

      to {
        transform: translateY($height);
      }
    }
  }
}
