$connection-loading-size: calc(var(--beat-saber-base-font-size) * 6.5);

.loading-indicator.connection-loading {
  @include loading-element($connection-loading-size);
  margin-left: $image-spacing;
}

// Placeholder loading indicator ring displayed while the beat-saber re-mounts
#beat-saber-root:empty {
  position: absolute;
  top: $edge-spacing;
  right: $edge-spacing;
  @include loading-element($connection-loading-size, darken($text-color, 50%), true);
}

#connection-root {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;

  .connection-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row nowrap;
    margin-bottom: calc(var(--beat-saber-base-font-size) * 1);
  }

  .connection-status {
    transition-delay: $transition-delay * 1;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
    font-size: calc(var(--beat-saber-base-font-size) * 3.5);

    > * {
      display: block;
    }

    .status {
      font-size: calc(var(--beat-saber-base-font-size) * 1.75);
    }
  }

  .time-elapsed {
    font-size: calc(var(--beat-saber-base-font-size) * 2);
    line-height: 1em;
  }
}
